//src\components\CustomUploader\index.tsx
import React, { useState } from "react";
import { UploadFile } from "@mui/icons-material";
import NotSupportedModal from "../NotSupportedModal"; // Import the modal
import "./CustomUploader.css";

interface CustomUploaderProps {
  onFileUpload: (file: File) => void;
}

const CustomUploader: React.FC<CustomUploaderProps> = ({ onFileUpload }) => {
  const [file] = useState<File | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const supportedFormats = ["video/mp4", "video/x-matroska", "video/quicktime"];

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      if (supportedFormats.includes(selectedFile.type)) {
        onFileUpload(selectedFile);
      } else {
        setShowModal(true);
      }
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const droppedFile = event.dataTransfer.files[0];
      if (supportedFormats.includes(droppedFile.type)) {
        onFileUpload(droppedFile);
      } else {
        setShowModal(true);
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  return (
    <div className="custom-uploader-container">
      <input
        type="file"
        id="file-upload"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <label
        htmlFor="file-upload"
        className="custom-uploader"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <div className="avatar">
          <UploadFile className="upload-file-filled" />
        </div>
        <div className="title">
          <div className="link">
            <div className="text-wrapper">Click to upload</div>
            <div className="underline" />
          </div>
          <p className="or-drag-and-drop">or drag and drop any video</p>
        </div>
        <div className="file-types">MP4, MOV, or MKV only</div>
        {file && <p className="file-name">{file.name}</p>}
      </label>

      {showModal && <NotSupportedModal onClose={handleCloseModal} />}
    </div>
  );
};

export default CustomUploader;
