import React, { useState } from 'react';
import './App.css';
import CustomUploader from './components/CustomUploader';
import VideoPlayer from './components/VideoPlayer';
import EnhancedResult from './components/EnhancedResult'; // Make sure you import this if needed

function App() {
  const [file, setFile] = useState<File | null>(null);
  const [enhancedVideoUrl, setEnhancedVideoUrl] = useState<string | null>(null);
  const [originalFps, setOriginalFps] = useState<string>('');
  const [enhancedFps, setEnhancedFps] = useState<string>('');
  const [codec, setCodec] = useState<string>('');
  const [targetCodec, setTargetCodec] = useState<string>('');

  const handleFileUpload = (uploadedFile: File) => {
    setFile(uploadedFile);
  };

  const handleBack = () => {
    setFile(null);
  };

  const handleEnhancementComplete = (enhancedUrl: string, originalFrames: string, enhancedFrames: string, originalCodec: string, newCodec: string) => {
    setEnhancedVideoUrl(enhancedUrl);
    setOriginalFps(originalFrames);
    setEnhancedFps(enhancedFrames);
    setCodec(originalCodec);
    setTargetCodec(newCodec);
  };

  const returnToMainMenu = () => {
    setFile(null);
    setEnhancedVideoUrl(null);
  };

  return (
    <div className="App">
      <h1 className="heading">AI Super Motion</h1>
      <div className="main-container">
        {file && enhancedVideoUrl ? (
          <EnhancedResult
            originalVideoUrl={URL.createObjectURL(file)}
            enhancedVideoUrl={enhancedVideoUrl}
            originalFps={originalFps}
            enhancedFps={enhancedFps}
            codec={codec}
            targetCodec={targetCodec}
            onMainMenu={returnToMainMenu}
          />
        ) : file ? (
          <VideoPlayer
            file={file}
            onBack={handleBack}
            onEnhancementComplete={handleEnhancementComplete}
          />
        ) : (
          <CustomUploader onFileUpload={handleFileUpload} />
        )}
      </div>
    </div>
  );
}

export default App;
