// src/components/EnhancedResult/index.tsx
import React, { useRef } from "react";
import { Typography, Box, Button } from "@mui/material";
import "./EnhancedResult.css";

interface EnhancedResultProps {
  originalVideoUrl: string;
  enhancedVideoUrl: string;
  originalFps: string;
  enhancedFps: string;
  codec: string;
  targetCodec: string;
  onMainMenu: () => void;
}

const EnhancedResult: React.FC<EnhancedResultProps> = ({
  originalVideoUrl,
  enhancedVideoUrl,
  originalFps,
  enhancedFps,
  codec,
  targetCodec,
  onMainMenu,
}) => {
  const originalVideoRef = useRef<HTMLVideoElement | null>(null);
  const enhancedVideoRef = useRef<HTMLVideoElement | null>(null);

  const syncPlay = () => {
    if (originalVideoRef.current && enhancedVideoRef.current) {
      originalVideoRef.current.currentTime =
        enhancedVideoRef.current.currentTime;
      originalVideoRef.current.play();
      enhancedVideoRef.current.play();
    }
  };

  const syncPause = () => {
    if (originalVideoRef.current && enhancedVideoRef.current) {
      originalVideoRef.current.pause();
      enhancedVideoRef.current.pause();
      originalVideoRef.current.currentTime =
        enhancedVideoRef.current.currentTime;
    }
  };

  return (
    <Box className="enhanced-result-container">
      <div className="video-comparison-container">
        <div className="video-container">
          <video
            ref={originalVideoRef}
            controls
            onPlay={syncPlay}
            onPause={syncPause}
          >
            <source src={originalVideoUrl} type="video/mp4" />
          </video>
          <div className="info-container">
            <div className="info-box">
              <div className="info-text-right">FPS: {originalFps}</div>
              <div className="info-text-left">Codec: {codec}</div>
            </div>
          </div>
          <Typography
            variant="caption"
            style={{
              position: "absolute",
              top: "5px",
              left: "5px",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              padding: "5px",
              borderRadius: "4px",
            }}
          >
            Original Video
          </Typography>
        </div>
        <div className="video-container">
          <video
            ref={enhancedVideoRef}
            controls
            onPlay={syncPlay}
            onPause={syncPause}
          >
            <source src={enhancedVideoUrl} type="video/mp4" />
          </video>
          <div className="info-container">
            <div className="info-box">
              <div className="info-text-right">FPS: {enhancedFps}</div>
              <div className="info-text-left">Codec: {targetCodec}</div>
            </div>
          </div>
          <Typography
            variant="caption"
            style={{
              position: "absolute",
              top: "5px",
              left: "5px",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              padding: "5px",
              borderRadius: "4px",
            }}
          >
            Enhanced Video
          </Typography>
        </div>
      </div>
      <Button
        className="main-menu-button"
        onClick={onMainMenu}
        style={{
          color: "#FFF",
          textAlign: "center",
          fontFamily: "Inter",
          fontSize: "20.063px",
          fontWeight: 500,
          lineHeight: "14.063px",
          letterSpacing: "0.176px",
          borderRadius: "8px",
          background: "#18005B",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          width: "211px",
          height: "50px",
          marginTop: "20px",
        }}
      >
        Main Menu
      </Button>
    </Box>
  );
};

export default EnhancedResult;
