// src/components/NotSupportedModal/index.tsx

import React from 'react';
import './NotSupportedModal.css';

interface NotSupportedModalProps {
  onClose: () => void;
}

const NotSupportedModal: React.FC<NotSupportedModalProps> = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>File Not Supported</h2>
        <p>Please upload a file in MP4, MKV, or MOV format.</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default NotSupportedModal;
