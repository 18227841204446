import React, { useEffect, useState, useRef } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Modal,
  Typography,
  Tooltip,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import "./VideoPlayer.css";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";

interface VideoPlayerProps {
  file: File;
  onBack: () => void;
  onEnhancementComplete: (
    enhancedUrl: string,
    originalFrames: string,
    enhancedFrames: string,
    originalCodec: string,
    newCodec: string
  ) => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  file,
  onBack,
  onEnhancementComplete,
}) => {
  const ffmpeg = useRef(new FFmpeg());
  const originalVideoUrl = URL.createObjectURL(file);
  const [fps, setFps] = useState<number>(30);
  const [isHardwareEncodingEnabled, setHardwareEncodingEnabled] =
    useState<boolean>(false);
  const [codec, setCodec] = useState<string>("Loading...");
  const [originalFps, setOriginalFps] = useState<string>("Loading...");
  const [progress, setProgress] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);

  const loadVideoData = async () => {
    if (!ffmpeg.current.loaded) {
      await ffmpeg.current.load();
    }
    await ffmpeg.current.writeFile(file.name, await fetchFile(file));
    try {
      ffmpeg.current.on("log", ({ message }) => {
        const fpsMatch = message.match(/, (\d+(\.\d+)?) fps,/);
        const codecMatch = message.match(/Video: (\w+)/);
        if (fpsMatch) {
          setOriginalFps(fpsMatch[1]);
        }
        if (codecMatch) {
          setCodec(codecMatch[1]);
        }
      });
      await ffmpeg.current.exec(["-i", file.name, "-hide_banner"]);
    } catch (error) {
      console.error("Error processing video data:", error);
    }
  };

  const handleSwitchChange = () => {
    if (codec !== "hevc" && codec !== "h265") {
      setHardwareEncodingEnabled(!isHardwareEncodingEnabled);
    }
  };

  const enhanceVideo = async () => {
    setShowModal(true);
    const fileNameWithoutExtension = file.name.replace(/\.\w+$/, ""); // Remove the extension from the file name
    const extension = file.name.split(".").pop(); // Get the extension of the file
    const outputFileName = `${fileNameWithoutExtension}_enhanced_${fps}.${extension}`;
    const startTime = Date.now();
    let initialTime = 0;

    await ffmpeg.current.writeFile(
      file.name,
      await fetchFile(originalVideoUrl)
    );

    ffmpeg.current.on("progress", (progressEvent: any) => {
      const { progress, time } = progressEvent;
      if (progress > 0 && initialTime === 0) {
        initialTime = time;
      }

      if (progress > 0) {
        // const elapsed = Date.now() - startTime;
        const percentage = Math.round(progress * 1000) / 10;
        let remainingMinutes = "Calculating...";
        if (progress > 0.01) {
          const estimatedTotalTime = time / progress;
          const remainingTime = estimatedTotalTime - time;
          remainingMinutes = Math.round(remainingTime / 15000 / 60) as any;
        }

        setProgress(
          `Enhancing: ${percentage}% - Remaining Time: ${remainingMinutes} min`
        );
      }
    });

    try {
      await ffmpeg.current.exec([
        "-i",
        file.name,
        "-filter:v",
        `minterpolate='mi_mode=mci:mc_mode=aobmc:vsbmc=1:fps=${fps}'`,
        "-y",
        outputFileName,
      ]);
      const data = await ffmpeg.current.readFile(outputFileName);
      const enhancedVideoUrl = URL.createObjectURL(
        new Blob([data], { type: `video/${extension}` })
      );

      // Trigger the enhancement complete callback
      onEnhancementComplete(enhancedVideoUrl, originalFps, fps.toString(), "H264", "H265");

      const link = document.createElement("a");
      link.href = enhancedVideoUrl;
      link.download = outputFileName; // Set the download attribute with the desired file name
      link.click(); // Trigger the download
    } catch (error) {
      console.error("Error enhancing video:", error);
      setProgress(`Failed to enhance video: ${(error as Error).message}`);
    } finally {
      setShowModal(false);
      // Cleanup files in FFmpeg FS if needed
      // ffmpeg.current.remove(file.name);
      // ffmpeg.current.remove(outputFileName);
    }
  };

  useEffect(() => {
    loadVideoData();
  }, [loadVideoData]);

  return (
    <div className="video-player-container">
      <div className="header">
        <ArrowBack className="back-icon" onClick={onBack} />
        <div className="file-path">{file.name}</div>
      </div>
      <video className="video-player" controls>
        <source src={originalVideoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="info-container">
        <div className="info-box">
          <div className="info-text-right">FPS: {originalFps}</div>
          <div className="info-text-left">Codec: {codec}</div>
        </div>
      </div>
      <div className="actions-container">
        <FormControl size="small" variant="outlined" className="dropdown">
          <InputLabel id="fps-select-label">FPS</InputLabel>
          <Select
            labelId="fps-select-label"
            id="fps-select"
            value={fps}
            label="FPS"
            onChange={(event) => setFps(event.target.value as number)}
          >
            <MenuItem value={30}>30 FPS</MenuItem>
            <MenuItem value={60}>60 FPS</MenuItem>
            <MenuItem value={120}>120 FPS</MenuItem>
          </Select>
        </FormControl>
        <button className="enhance-button" onClick={enhanceVideo}>
          Enhance
        </button>
      </div>
      <div className="hardware-encoding-container">
        <span className="hardware-encoding-text">Enable Hardware Encoding</span>

        <Tooltip
          color="red"
          background-color="red"
          title="It will encode in High Efficient Video Codec (H.265) which will reduce output size with super quality. But some older devices might not support it. It takes double the time in process."
          arrow
        >
          <Switch
            checked={isHardwareEncodingEnabled}
            onChange={handleSwitchChange}
            color="primary"
            size="medium"
            disabled={codec === "hevc" || codec === "h265"} // Only disable if the video is already H.265
          />
        </Tooltip>
      </div>

      <Modal
        component="div"
        open={showModal}
        onClose={() => {}} // Remove functionality to close by clicking on backdrop or pressing escape
        disableEscapeKeyDown={true} // Prevents closing modal by pressing escape key
      >
        <div className="modal-content">
          <Typography variant="h6" color="inherit">
            {progress}
          </Typography>
        </div>
      </Modal>
    </div>
  );
};

export default VideoPlayer;
